import '/src/sass/app.scss';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';

window.Alpine = Alpine;
Alpine.plugin(collapse);

Alpine.data('header', () => ({
  megamenuOpen: false,
  isPinned: false,

  init() {
    window.addEventListener(
      'scroll',
      () => {
        if (this.isPinned) {
          if (window.scrollY < 50) {
            this.isPinned = false;
          }
        } else {
          if (window.scrollY > 100) {
            this.isPinned = true;
          }
        }

        document.documentElement.style.setProperty('--header-height', `${this.$refs.header.offsetHeight}px`);
      },
      { passive: true }
    );
  },

  close() { this.megamenuOpen = false },
  toggle() { this.megamenuOpen = !this.megamenuOpen },
}));

Alpine.data('scrollToContent', () => ({
  init() {
    this.$el.addEventListener(
      'click',
      () => {
        const target = document.querySelector('[data-page-content]');
        if (!target) return;
        // Factor in sticky elements.
        const headerHeight = document?.querySelector('header')?.offsetHeight || 0;
        const leagueHeaderHeight = document?.querySelector('[data-league-header]')?.offsetHeight || 0;

        window.scrollTo({
          behavior: 'smooth',
          top:
            target.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            headerHeight -
            leagueHeaderHeight,
        });
      }
    );
  },
}));

Alpine.data('mssFrame', () => ({
  init() {
    const iframe = this.$el;
    const resizeIframe = () => {
      if (iframe?.contentWindow?.document?.body?.scrollHeight) {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
      }
    };
    iframe.addEventListener('load', resizeIframe);
    window.addEventListener('resize', resizeIframe);
  },
}));

Swiper.use([Pagination, Autoplay]);

new Swiper('.swiper-hero', {
  slidesPerView: 1,
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  }
});

Alpine.data('carousel', (mobileSlides = 1, tabletSlides = 1, desktopSlides = 3, autoPlay = false) => ({
  swiper: null,
  canGoPrevious: false,
  canGoNext: true,

  init() {
    this.swiper = new Swiper(this.$el, {
      slidesPerView: mobileSlides,
      spaceBetween: 20,
      autoplay: autoPlay ? {
        delay: 5000, // Adjust the delay as needed
        disableOnInteraction: false,
      } : false,
      breakpoints: {
        768: {
          slidesPerView: tabletSlides,
        },
        1024: {
          slidesPerView: desktopSlides,
        },
      },
      on: {
        slideChange: () => {
          this.canGoPrevious = !this.swiper.isBeginning;
          this.canGoNext = !this.swiper.isEnd;
        },
        reachEnd: () => {
          this.canGoNext = false;
        },
        reachBeginning: () => {
          this.canGoPrevious = false;
        },
        resize: () => {
          this.canGoNext = !this.swiper.isEnd;
          this.canGoPrevious = !this.swiper.isBeginning;
        },
      },
    });
  },

  previous() {
    if (this.canGoPrevious) {
      this.swiper?.slidePrev?.();
    }
  },
  next() {
    if (this.canGoNext) {
      this.swiper?.slideNext?.();
    }
  },
}));

Alpine.data('scoresCarousel', () => ({
  swiper: null,
  canGoPrevious: false,
  canGoNext: true,

  init() {
    this.swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },
      on: {
        slideChange: () => {
          this.canGoPrevious = !this.swiper.isBeginning;
          this.canGoNext = !this.swiper.isEnd;
        },
        reachEnd: () => {
          this.canGoNext = false;
        },
        reachBeginning: () => {
          this.canGoPrevious = false;
        },
        resize: () => {
          this.canGoNext = !this.swiper.isEnd;
          this.canGoPrevious = !this.swiper.isBeginning;
        },
      }
    });
  },
  previous() {
    if (this.canGoPrevious) {
      this.swiper?.slidePrev?.();
    }
  },
  next() {
    if (this.canGoNext) {
      this.swiper?.slideNext?.();
    }
  },
}));

Alpine.start();

window.myss_GetSkateSafeScoreNode = (data) => `
  <article class="swiper-slide flex flex-col items-center">
    <p class="mb-1 text-secondary text-[1.4375rem] leading-none uppercase font-primary">
      ${data.gametime}
    </p>
    <p class="mb-5 text-primary text-lg leading-tight font-primary line-clamp-2">
      ${data.leaguename + (parseInt(data.bracketid) > 0 ? ' Playoffs' : '')}
    </p>

    <div class="flex justify-center items-start">
      <div class="flex flex-col flex-1 justify-center items-center gap-5 text-center">
        <p class="text-secondary text-lg leading-none uppercase font-primary h-[2em] line-clamp-2">
          ${data.hometeam}
        </p>
        <p class="flex items-center justify-center bg-white w-[86px] h-[94px] text-primary leading-none text-[52px] font-primary font-medium">
          ${data.homescore}
        </p>
      </div>
      <div class="flex flex-col flex-1 justify-center items-center gap-5 text-center">
        <p class="text-secondary text-lg leading-none uppercase font-primary h-[2em] line-clamp-2">
          ${data.awayteam}
        </p>
        <p class="flex items-center justify-center bg-white w-[86px] h-[94px] text-primary leading-none text-[52px] font-primary font-medium">
          ${data.awayscore}
        </p>
      </div>
    </div>
  </article>
`;

window.myss_GetScoresForSkateSafe = (lid, slid, sportid, target) => {
  if (typeof myssQ === 'undefined') return;

  myssQ(target).html(`
    <div class="flex flex-col items-center justify-center gap-4 text-center">
      <img
        alt="loading spinner"
        class="w-8 h-8 aspect-square"
        src="https://app.mysportsort.com/images/loading.gif"
      />
      <p class="type-body text-primary">
        Please wait while we retrieve the scores
      </p>
    </div>
  `);

  var lData = {
    lid: lid === undefined ? 0 : lid,
    an: myss_an,
    slid: slid === undefined ? 0 : slid,
    display: 1,
    futuregames: 0,
    sportid: sportid === undefined ? 0 : sportid,
    securetoken: myss_fbsecuretoken,
  };

  myssQ.ajax({
    url: myss_fetchjson_path + 'js_getscores.php',
    dataType: 'jsonp',
    type: 'GET',
    data: lData,
    error: () => myssQ(target).html(myss_ConnectionError()),
    success: (data) => {
      if (CheckError(data, target)) return;

      if (data.scores === undefined) {
        myssQ(target).html(
          "There was an error getting scores.  Please contact info@mysportsort.com"
        );
        return;
      }

      const markup = `
        <div class="text-center">
          <h2 class="type-h1 mb-2.5">
            Today's Games
          </h2>

          <div class="flex flex-col justify-center items-center lg:flex-row gap-x-8 gap-y-4 mb-8 text-[1.25rem] leading-normal uppercase font-primary text-grayscale-dark">
            <span>
              Games on <button onClick="myss_GetScoresForSkateSafe(0, 0, 0, '#skatesafescorescroller');">
                ${data.scores.header.gamedate}
              </button>
            </span>

            <button
              class="flex items-center gap-1"
              onClick="myss_GetScoresForSkateSafe(0, 0, 0, '#skatesafescorescroller');"
            >
              <svg
                class="w-5 h-5 text-secondary"
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path fill="currentColor" d="M483.515 28.485L431.35 80.65C386.475 35.767 324.485 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h28.045c6.353 0 11.613-4.952 11.973-11.294C66.161 141.649 151.453 60 256 60c54.163 0 103.157 21.923 138.614 57.386l-54.128 54.129c-7.56 7.56-2.206 20.485 8.485 20.485H492c6.627 0 12-5.373 12-12V36.971c0-10.691-12.926-16.045-20.485-8.486zM491.699 256h-28.045c-6.353 0-11.613 4.952-11.973 11.294C445.839 370.351 360.547 452 256 452c-54.163 0-103.157-21.923-138.614-57.386l54.128-54.129c7.56-7.56 2.206-20.485-8.485-20.485H20c-6.627 0-12 5.373-12 12v143.029c0 10.691 12.926 16.045 20.485 8.485L80.65 431.35C125.525 476.233 187.516 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"></path>
              </svg>

              <span class="whitespace-nowrap">
                Last Refresh - ${data.scores.header.systemtime}
              </span>
            </button>
          </div>

          <div
            x-data="scoresCarousel()"
            class="flex gap-8 items-center"
          >
            <div class="flex flex-col gap-2">
              <button
                class="button-square"
                aria-label="Previous"
                @click="previous"
                :disabled="!canGoPrevious"
              >
                <svg
                  class="rotate-90"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                </svg>
              </button>
              <span
                class="text-[1.3rem] leading-none uppercase text-primary font-primary transition-opacity"
                :class="canGoPrevious ? 'divide-opacity-100' : 'opacity-50'"
              >
                Earlier Games
              </span>
            </div>

            <div class="swiper" x-ref="swiper">
              <div class="swiper-wrapper">
                ${data.scores.games.map((game) => myss_GetSkateSafeScoreNode(game)).join('')}
              </div>
            </div>

            <div class="flex flex-col gap-2">
              <button
                class="button-square"
                aria-label="Next"
                @click="next"
                :disabled="!canGoNext"
              >
              <svg
                class="-rotate-90"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
              </svg>
              </button>
              <span
                class="text-[1.3rem] leading-none uppercase text-primary font-primary transition-opacity"
                :class="canGoNext ? 'divide-opacity-100' : 'opacity-50'"
              >
                Later Games
              </span>
            </div>
          </div>
        </div>
      `;

      myssQ(target).html(markup);
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  () => {
    const scoresWidget = document.getElementById('skatesafescorescroller');
    if (scoresWidget) {
      window.myss_GetScoresForSkateSafe(0, 0, 0, '#skatesafescorescroller')
    }
  }
);
